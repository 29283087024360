@import "../variables/colors.scss";

.ant-card {
  border-radius: 1.5rem;
}

.card-list {
  padding: 7px 1px;
  background-color: $white;
  border-radius: 1rem;
}

.card-title {
  margin: 4px;
}

.card-list-line {
  border-bottom: 1px solid $semiDark;
  border-radius: 0.5rem;
}

.card-item:hover {
  color: $primary;
}

.card-item-selected {
  border-radius: 5px !important;
  background-color: $white;
  color: $primary;
  font-weight: bold;
  font-size: 2.2rem;
}

.card-border-selected {
  border-left: 3px solid $primary;
  border-radius: 0.5rem;
}

.card-border-selected-en {
  border-right: 3px solid $primary;
  border-radius: 0.5rem;
}
