@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
// @import 'antd/dist/antd.css';
@import "./variables/colors.scss";
@import "./variables/fonts.scss";
@import "./button/index.scss";
@import "./popover/index.scss";
@import "./button/index.scss";
@import "./menu/index.scss";
@import "./scroll-bar/index.scss";
@import "./typography/index.scss";
// @import "~react-vis/dist/style";
@import "./card/index.scss";
@import "./tab-bar/index.scss";
@import "./tooltip/index.scss";
@import "./image-preview/index.scss";

//fonts

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; // 1rem = 10px
}

body {
  margin: 0;
  font-family: $main-arabic-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  scroll-behavior: smooth;
  background-color: $backgroundPrim;
}

.ant-card-body {
  height: 100%;
  background-color: $white;
  border-radius: 20px !important;
}

.text-primary {
  color: $primary;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

.ant-layout-content {
  background-color: $backgroundPrim;
  margin-left: 42px;
  margin-top: 24px;
  margin-right: 42px;
}

.ant-layout {
  background-color: $backgroundPrim;
}

.ant-layout-footer {
  padding: 10px 50px;
  background-color: $backgroundPrim;
}

.ant-typography a {
  color: $primary;
}

@media (max-width: 1024px) {
  .ant-layout-content {
    margin: 10px 5px 0 15px;
  }
}

@media (max-width: 990px) {
  .ant-layout-content {
    margin: 10px 5px 0 15px;
  }
}
