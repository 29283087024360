@import "../variables/colors.scss";
@import "../variables/fonts.scss";

.rv-discrete-color-legend-item__color {
  height: 15px;
  border-radius: 2px;
}

.rv-discrete-color-legend-item {
  display: contents;
}

.dashdoard-items {
  align-items: center;
}

.ant-btn.button-primary {
  box-sizing: border-box;
  position: absolute;
  width: 93px;
  height: 26px;
  border: 1px solid $primary;
  border-radius: 20px;
  background-color: $white;
  color: $primary;
  text-align: center;
  padding: 1px;
  box-sizing: border-box;
}

.card-chart {
  margin-inline: 0.2rem;
}

.card-welcome {
  height: 58px;
  width: 100%;
  margin-inline: 0.5rem;
}

.card-bar-chart {
  height: 490;
  background-color: $primary;
}

.card-welcome {
  background-color: $primary;
}

.card-welcome .ant-card-body {
  background-color: $light-semiGray;
}

.card-bar-chart .ant-card-body {
  background-color: $light-semiGray;
}

.card-bar-number-chart {
  height: 300;
}

.card-bar-age-chart {
  height: 510;
}

.card-donut-chart {
  height: 460px;
}

.card-information {
  height: 270px;
  overflow: auto;
}

.h3 {
  color: $primary;
  font-size: "20px";
}

.icon {
  display: "inline";
  margin: "2px";
}

.center {
  margin-top: -7px;
}
