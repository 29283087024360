@import "../variables/colors.scss";

.sider-layout {
  max-width: 55px !important;
  min-width: 55px !important;
  height: 449px !important;
  flex-shrink: 0;
  margin-left: 25.7px;
  margin-top: 47.1px;
  margin-bottom: 260px;
  background-color: $backgroundPrim !important;
}

.app-sider {
  background-color: $backgroundPrim;
}

.icon-img {
  width: 22.602px;
  height: 22.602px;
  margin-top: 32px;
}

.ant-menu-root .sidebar-m-i {
  width: 54.429px;
  height: 84.796px;
  border-radius: 53px;
  background: $light-semiGray;
}

.ant-menu {
  background: $backgroundPrim !important;
  width: 100% !important;
  border-inline-end: 0 !important;
}

.ant-layout-sider {
  background: $backgroundPrim !important;
}

@media (max-width: 990px) {
  .sider-layout {
    margin-left: 10px;
  }

  .ant-menu-root .sidebar-m-i {
    width: 52.429px;
    height: 78.796px;
  }
}
