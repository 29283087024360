@import "../variables/colors.scss";

.ant-select-dropdown {
  border-radius: $form-element-border-radius;
  padding: 0;
  margin: 0;
}

.ant-select-item:first-child {
  border-top-left-radius: $form-element-border-radius;
  border-top-right-radius: $form-element-border-radius;
}

.ant-select-item:last-child {
  border-bottom-left-radius: $form-element-border-radius;
  border-bottom-right-radius: $form-element-border-radius;
}

.ant-select-multiple .ant-select-selection-item,
.ant-select-multiple .ant-select-selection-item {
  background: $primary-extra-light !important;
  background-color: $primary-extra-light !important;
  border-radius: $form-element-border-radius !important;
}

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: middle !important;
}

.ant-select-item-option-selected {
  background: $primary-extra-light !important;
  background-color: $primary-extra-light !important;
}

.ant-select-arrow {
  color: $black;
}

.ant-select .ant-select-selector {
  border: none !important;
  border-bottom: 2px solid $primary !important;
  border-color: $primary !important;
  background-color: $form-element-background !important;
  &:focus {
    border-color: $primary !important;
  }
}
