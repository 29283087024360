@import "../variables/colors.scss";

.device-card {
  width: 20rem;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $light-semiGray;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.device-card .ant-card-body {
  background-color: $light-semiGray;
  color: $black;
  font-weight: bold;
  font-size: 30px;
}

.device-card .ant-card-head {
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  text-align: center;
}

.device-card:hover {
  transform: scale(1.1);
  background-color: $light-semiGray;
  color: #ff0000;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}

.device-name {
  font-size: 50px;
  text-align: center;
}

.device-date {
  font-size: 30px;
  text-align: center;
}
