@import "../variables/colors.scss";
@import "../variables/shadwos.scss";

.ant-btn {
  border-radius: $button-radius;
  color: $white;
}

.ant-btn-lg {
  padding-right: 5.6rem;
  padding-left: 5.6rem;
  padding-top: 1.2rem;
  padding-bottom: 4.6rem;
  font-weight: bold;
  border-radius: $button-radius;
  border-radius: 6rem;
  font-size: 2.1rem;
}

.ant-btn-primary {
  background: $primary !important;
}

.ant-btn-primary:hover {
  background: $primary !important;
  box-shadow: $defult-shadow;
}

.ant-btn-default {
  color: $primary;
}

.ant-btn-default:hover {
  border-color: $primary !important;
  box-shadow: $defult-shadow;
}

.savebtn {
  background: $form-element-background !important;
  color: $primary !important;
}

.disabledbtn {
  background: $gray;
  color: $gray;
}

.cancelbtn {
  background: $form-element-background !important;
  color: $gray !important;
}

.cancelbtn:hover,
.cancelbtn:active {
  color: $danger !important;
  border-color: $danger !important;
  box-shadow: $danger-shadow;
}
