@import "../variables/colors.scss";
@import "../variables/fonts.scss";
@import "../variables/shadwos.scss";

.card-container {
  height: 95vh;
}

.form-login {
  width: 100%;
  height: 50%;
  border-radius: 5rem 5rem 5rem 5rem !important;
  box-shadow: $defult-shadow;
  padding: 10% 14%;
}

.d-form {
  width: 560px;
  margin: auto;
  display: flex;
}

.ant-login-btn {
  height: 3.5rem !important;
  width: 8rem !important;
  margin-left: 10px;
  font-size: medium;
}

.ant-login-btn:hover {
  box-shadow: $defult-shadow;
}

#login-font {
  color: $black;
  font-size: 4rem;
  font-family: $main-arabic-font !important;
  font-weight: bold;
  // text-align: left;
}

#normal_login {
  height: 100%;
  width: 100%;
}

.contentStyle {
  height: 100%;
}

.input-login,
.ant-input {
  border: none;
  border-bottom: 1px solid $gray;
  outline: none;
  width: 100% !important;
  background-color: $white;
}

.text-create-account {
  font-size: small;
  margin-left: 10px;
}

@media (max-width: 450px) {
  .d-form {
    width: 400px;
  }

  .ant-login-btn {
    width: 8rem !important;
    font-size: 15px;
  }

  .text-create-account {
    font-size: 12px;
  }

  .ant-typography a {
    font-size: 12px;
  }
}

@media (max-width: 375px) {
  .form-login {
    padding: 10% 10%;
  }
}
