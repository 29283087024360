@import "../variables/colors.scss";
@import "../variables/fonts.scss";

.ant-picker-panel-rtl {
  direction: ltr;
}

.ant-picker.ant-picker-borderless {
  background-color: $form-element-background !important;
}

.ant-picker.ant-picker-borderless.ant-picker-disabled {
  background-color: $white !important;
}
.ant-picker-panel,
.ant-picker-panel-container,
.ant-picker-date-panel {
  border-radius: $form-element-border-radius !important;
  overflow: hidden !important;
}
.ant-picker-header {
  border-radius: $form-element-border-radius;
  background-color: $form-element-background !important;
}
.ant-picker-cell-inner,
.ant-picker-cell-inner::before {
  border-radius: $form-element-border-radius !important;
}

/////////////////////////////////////////////////////////
/////////////   Component react-datepicker //////////////
/////////////////////////////////////////////////////////

//  roote datePicker
.react-datepicker {
  border: none !important;
  border-radius: $form-element-border-radius !important;
}

.w-full {
  width: 100%;
}

.react-datepicker__month-container {
  background-color: $white;
  width: 28rem;
  color: $black;
  font-size: 1.3rem;
  font-family: $main-arabic-font;
  border-radius: $form-element-border-radius !important;
  overflow: hidden !important;
  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    margin: 0.6rem !important;
    color: $black !important;
    width: 2.3rem !important;
    .react-datepicker__day--selected {
      background-color: --primary-color !important;
    }
  }

  .react-datepicker__header {
    border-radius: $form-element-border-radius !important;
    background-color: $form-element-background !important;
  }

  .react-datepicker__month {
    padding: 1rem 0;
  }
  .react-datepicker__day--outside-month {
    color: $gray !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover {
    background-color: $primary;
    color: $white;
    border-radius: 1.3rem;
  }

  .react-datepicker__month-text {
    display: inline-block;
    width: 5rem;
    margin: 0.5rem;
    font-size: 1rem;
    padding: 0.2rem;
    &:hover {
      background-color: --primary-color;
    }
  }
}
