@import "../variables/colors.scss";
@import "../variables/shadwos.scss";

.ant-modal-content {
  border-radius: $form-element-border-radius;
}

.ant-modal-header {
  border-radius: $form-element-border-radius !important;
  background-color: $primary-extra-light !important;
  padding: 8px !important;
}

.ant-modal-title {
  color: $white;
}

.modal-error .ant-modal-header {
  background-color: $danger !important;
}

.ant-modal-close {
  right: 27px !important;
  top: 23px !important;
  background-color: rgba($white, 0.7);
  border-radius: $form-element-border-radius;
  transition: right 0.2s ease-in-out, top 0.2s ease-in-out;
  will-change: top, right;
  margin: 0.75rem;

  &:hover {
    box-shadow: $danger-shadow;
    background-color: rgba($white, 0.9);

    .ant-modal-close-x {
      color: $danger;
    }
  }

  .ant-modal-close-x {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
