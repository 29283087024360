@import "../variables/colors.scss";

.ant-menu.ant-menu-horizontal .ant-menu-item-selected {
  border-radius: 5px !important;
  background-color: $hover !important;
  margin-left: 0px;
  color: $white;
  font-weight: bold;
  font-size: medium;
}

.ant-menu.ant-menu-horizontal {
  background: $primary !important;
  border-radius: 11px 11px 0px 0px;
}

.ant-menu-horizontal .ant-menu-item {
  color: $white;
  text-align: center;
  font-family: $main-arabic-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
