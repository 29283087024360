@import "styles/variables/colors.scss";
@import "styles/variables/fonts.scss";

.patient-information {
  margin-bottom: 5px;
  background: $light;
  padding-left: 20px;
}

.p-i-card {
  margin: 16px 19px 20px 0px;
  height: 100px;
}

.p-i-card.one .ant-card-body,
.p-i-card.two .ant-card-body,
.p-i-card.three .ant-card-body {
  border-radius: 0 !important;
  color: #525767;
  font-family: $main-arabic-font;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  height: 90px;
  padding: 5px 8px;
}

.p-i-card.one {
  background-color: $blue-gray;
  color: $white !important;
  font-family: $main-arabic-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.col-p-i-card.one {
  margin-inline-start: 2.5%;
}

.col-p-i-card {
  margin-inline-start: 9%;
}

.p-i-card.one .ant-card-body {
  background-color: $backgroundPrim;
}

.p-i-card.two {
  background-color: #fff2b5;
  color: #525767 !important;
  font-family: $main-arabic-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.p-i-card.two .ant-card-body {
  background-color: #fff7cf;
}

.p-i-card.three {
  background-color: #ec9a3a;
  color: $white !important;
  font-family: $main-arabic-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.p-i-card.three .ant-card-body {
  background-color: $backgroundPrim;
  overflow: auto;
}

.col-avatar {
  background-color: $backgroundPrim;
  border-radius: 6px;
  width: 105.946px;
  height: 110px;
}

.antd-avatar {
  margin: 14px 12px;
}

.icon-h {
  display: inline;
}

.ant-row.row-info {
  border-bottom: 1px solid $hover;
  padding: 3px;
  font-family: $main-arabic-font;
  font-size: 16px;
  font-weight: 600;
  line-height: 31px;
}

.col-row-info {
  margin-inline-start: 1.1%;
}

.b-s-page {
  margin: 10px 22px 0 0;
}

.body-page {
  margin-right: 30px;
}

.p-i-card-side {
  background-color: $blue-gray;
  color: $white !important;
  font-family: $main-arabic-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.p-i-card-side .ant-card-head {
  padding: 8px;
}

.p-i-card-side .ant-card-body {
  background-color: $backgroundPrim;
  border-radius: 0 !important;
  color: #525767;
  font-family: $main-arabic-font;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 0px;
}

.card-row-header .ant-card-body {
  background-color: #cccccc;
  padding: 20px 5px 20px 5px;
}

.card-row .ant-card-body {
  background-color: #e8e8e8;
  padding: 22px;
}

.card-row-side .ant-card-body {
  background-color: #e8e8e8;
  padding: 14px;
}

.row-side {
  padding: 8px;
  border-radius: 10px;
}

.title-header {
  border-radius: 53px;
  background: $light-semiGray;
  font-size: 13px !important;
  font-weight: 700;
  line-height: 1.5;
  padding: 6px;
}

.date-asses {
  background-color: $semiGreen;
  border: 3px solid $secondry;
  border-radius: 7px;
  font-weight: bold;
  font-size: 15px;
  padding: 0px 10px;
}

@media (max-width: 1440px) {
  .ant-typography {
    font-size: 10px !important;
  }

  .col-p-i-card.one {
    margin-inline-start: 10.5%;
  }

  .col-p-i-card {
    margin-inline-start: 2%;
  }

  .patient-information {
    padding-left: 15px;
  }

  .col-row-info {
    margin-inline-start: 1.1%;
  }

  .title-header {
    font-size: 12px !important;
  }
}

@media (max-width: 1024px) {
  .ant-typography {
    font-size: 8px !important;
  }

  .col-p-i-card {
    margin-inline-start: 2%;
  }

  .col-p-i-card.one {
    margin-inline-start: 4.5%;
  }

  .patient-information {
    padding-left: 8px;
  }

  .col-row-info {
    margin-inline-start: 1.1%;
  }
}

@media (max-width: 990px) {
  .card-row-header .ant-card-body {
    padding: 12px;
  }
  .ant-typography {
    font-size: 8px !important;
  }

  .ant-row.row-info {
    padding: 3px;
    font-size: 12px;
    line-height: 20px;
  }

  .col-p-i-card {
    margin-inline-start: 2%;
  }

  .col-p-i-card.one {
    margin-inline-start: 2.5%;
  }

  .patient-information {
    padding-left: 2px;
  }

  .col-row-info {
    margin-inline-start: 0.1%;
  }

  .icon-h {
    height: 17px;
    width: 16px;
  }
  .p-row-info {
    font-size: 11px;
  }

  .body-page {
    margin-right: 8px;
  }

  .p-i-card.one .ant-card-body,
  .p-i-card.two .ant-card-body,
  .p-i-card.three .ant-card-body {
    font-size: 10px;
    line-height: 12px;
    height: 60px;
  }
  .title-header {
    font-size: 9px !important;
    padding: 4px;
  }
}

@media (max-width: 450px) {
  .col-p-i-card {
    min-width: 10rem !important;
  }

  .col-row-info {
    min-width: 18rem;
  }

  .p-i-card {
    margin: 9px 5px 12px 0px;
  }

  .b-s-page {
    display: block;
  }

  .body-page {
    min-width: 32rem;
  }

  .p-i-card-side {
    min-width: 32rem;
    margin-top: 8px;
  }
}
