@import "styles/variables/colors.scss";
@import "styles/variables/fonts.scss";

.guid-panel {
  margin-left: 20px;
}

.item-panel {
  margin-top: 5px;
}

.guid-panel-text {
  text-align: center;
  font-size: 25px;
  height: 47px;
  background-color: $primary;
  color: $white;
  border-radius: 15px 15px 0px 0px;
}

.title {
  color: $orange;
  font-weight: bold;
}

.title-ac {
  font-weight: bold;
}

.title-missing_info {
  color: $danger;
  font-weight: bold;
}

.section {
  background-color: $light-semiGray;
  padding: 5px;
  border-radius: 10px;
}

.a-r {
  padding: 17px;
}

.t-r {
  padding: 12px;
}

.pa-btn-step {
  border: none;
  margin-top: none;
  background-color: transparent !important;
}

.pa-btn-step:hover {
  width: 35px;
}

.row-section {
  margin-bottom: 5px;
}

.antd-avatar {
  margin: 12px 10px;
}

.patient-info .ant-card-body {
  padding: 3px;
}
.ant-row.row-info {
  border-bottom: 1px solid $hover;
  padding: 3px;
  font-size: 16px;
  font-weight: 600;
  line-height: 31px;
}

.col-row-info {
  margin-inline-start: 4.1%;
}

.icon-h {
  display: inline;
}

.summary-section {
  background-color: $primary-extra-light;
  border-radius: $form-element-border-radius;
  overflow: auto;
  height: 105px;
}

.pa-card .ant-card-head {
  background-color: $primary;
  color: $white;
}

.pa-card .ant-card-body {
  max-height: 90px;
  overflow: auto;
}

.form-step-four {
  max-height: 45rem;
  overflow: auto;
}

.test .dropzone {
  height: 13rem;
}
