@import "../variables/colors.scss";
@import "../variables/fonts.scss";
@import "../variables/shadwos.scss";

.steps .ant-steps-item {
  padding-inline-start: 0 !important;
}

.steps .ant-steps-item-content {
  display: block !important;
}

.steps .ant-steps-item-title::after {
  content: none;
}

.ant-steps .ant-steps-item-title {
  font-size: 13px;
  line-height: 25px;
}

.steps .ant-steps-item {
  .ant-steps-icon {
    color: $primary !important;
  }
  .ant-steps-item-title {
    color: $primary !important;
  }
  &-active {
    .ant-steps-icon {
      color: $white !important;
    }
  }
  &-finish {
    .ant-steps-icon {
      color: $primary !important;
    }
  }
  &:hover {
    .ant-steps-item-icon {
      box-shadow: $defult-shadow;
    }
    // .ant-steps-item-title {
    //   color: $secondry !important; // Hover title color
    // }
  }
}

.steps .ant-steps-item-active .ant-steps-item-icon {
  background: $primary !important;
}

.steps .ant-steps-item-icon {
  border-radius: 11px;
  background: $light-gray !important;
  border-color: $light-gray !important;
  color: $black !important;
  width: 49.841px;
  height: 37.994px;
}

.steps .ant-steps-item:last-child .ant-steps-item-active .ant-steps-icon {
  color: $black !important;
}

.steps .ant-steps-icon::after {
  position: absolute;
  top: 9px;
  display: block;
  margin-left: 49px;
  width: 9999px;
  height: 10px;
  content: "";
  // border-radius: 40px;
  background: $light-gray;
}

.steps .ant-steps-item:last-child .ant-steps-icon::after {
  content: none !important;
}

.steps {
  margin-top: 10px;
}

@media (max-width: 990px) {
  .steps .ant-steps-icon::after {
    width: 10em;
  }
}

@media (max-width: 450px) {
  .steps .ant-steps-icon::after {
    width: 26rem;
  }
}
