@import "styles/variables/colors.scss";
@import "styles/variables/fonts.scss";

.assesment-contant {
  background: $light-semiGray;
}

.one-step {
  margin-bottom: 10px;
}

.col-s {
  display: flex;
}

.one-step-header-right {
  background: $white;
  margin-top: 10px;
  padding: 5px;
  border-radius: 15px;
}

.one-step-header-left {
  background: $blue-gray;
  font-size: 11px;
  margin-top: 10px;
  padding: 5px;
  border-radius: 0px 10px 10px 0px;
}

.btn-step {
  margin-top: 36rem;
  border: none;
  background-color: transparent !important;
}

.btn-step:hover {
  width: 35px;
}

.row-card-asess {
  margin: 10px 0px !important;
}

.card-asess-border:not(:last-child)::after {
  position: absolute;
  top: 41px;
  display: block;
  margin-left: 96%;
  width: 6%;
  height: 76px;
  content: "";
  // border-radius: 10px;
  background: #ffffff;
  z-index: 1;
}

.card-asess-border-down::after {
  position: absolute;
  left: 29%;
  top: 152px;
  display: block;
  width: 42%;
  height: 22px;
  content: "";
  // border-radius: 10px;
  background: #ffffff;
  z-index: 1;
}

.card-asess .ant-card-body {
  background: #efefef;
  height: 10rem;
  margin: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-asses-f {
  margin-top: 10px;
  justify-content: center;
}

.full-width {
  width: 90%;
  margin-top: 7px;
}
.card-body-text {
  text-align: center;
  font-size: 14px;
}

.card-asess-chart .ant-card-body {
  background: #efefef;
  // display: grid;
}

.tag-step-five.header {
  font-weight: bold;
  color: $white !important;
}

// .tagContent-step-five:last-child {
//   font-weight: bold;
// }

.tagContent-step-five .total {
  font-weight: bold;
}

.tag-step-five {
  width: 90%;
  text-align: center;
  margin: 2px;
  padding: 3px 2px;
  color: $black !important;
}

.confirm-contant {
  background: $form-element-background;
  border-radius: $form-element-border-radius;
  margin-bottom: 10px;
}

.tooltip-table .ant-tooltip-inner {
  background-color: $white !important;
  min-width: 50rem !important;
  // max-height: 20em !important;
  // overflow: auto;
}

.tooltip-table .ant-tooltip-arrow:before {
  background-color: $white !important;
}

.table-hover .ant-table-title {
  padding: 1px;
}

.table-hover .ant-table-row {
  height: 2rem;
  margin-bottom: unset;
}

.table-hover .ant-table-row .ant-table-cell {
  padding: 5px !important;
}

@media (max-width: 1440px) {
  .card-asess-border:not(:last-child)::after {
    margin-left: 95%;
  }
}

@media (max-width: 1024px) {
  .card-asess-border:not(:last-child)::after {
    margin-left: 94%;
    width: 7%;
  }
}

@media (max-width: 990px) {
  .card-asess-border:not(:last-child)::after {
    margin-left: 92%;
    width: 10%;
  }

  .full-width {
    width: 125%;
  }
}

@media (max-width: 450px) {
  .card-asess-border:not(:last-child)::after {
    content: none;
  }

  .card-asess-border-down::after {
    content: none;
  }

  .card-asess-border {
    min-width: 14rem;
  }

  .col-card-asess {
    min-width: 14rem;
  }

  .btn-step {
    margin-top: 36rem;
    z-index: 1;
  }
}
