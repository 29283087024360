@import "../variables/colors.scss";
@import "../variables/fonts.scss";
//alert
.alert-success,
.Toastify__toast--success {
  background-color: $semiGreen !important;
  color: $black !important;
  font-size: 14px;
  & .alert-heading {
    box-shadow: none;
    font-weight: bold;
  }
}
.alert-danger,
.Toastify__toast--error {
  background: rgba($danger, 1) !important;
  font-size: 14px;
  color: $black !important;
  & .alert-heading {
    box-shadow: none;
    font-weight: bold;
  }
}
.alert-info {
  background: rgba($color: $hover, $alpha: 0.3) !important;
  color: $primary !important;
  font-weight: 600 !important;
}
//toast styling
.Toastify__toast-container--top-left {
  width: 16%;
  position: fixed;
  z-index: 100000;
}
.Toastify__toast {
  border-radius: $form-element-border-radius !important;
  font-family: $main-arabic-font !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 1rem !important;
}
.Toastify__close-button {
  opacity: 0 !important;
}
.toast__open {
  position: relative;
  animation-name: toastOpenAnimation;
  animation-duration: 0.2s;
}
.toast__close {
  position: relative;
  animation-name: toastCloseAnimation;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}

.Toastify__toast-icon {
  width: 25px;
}

@keyframes toastOpenAnimation {
  from {
    clip-path: circle(0% at 0% 0%);
  }

  to {
    clip-path: circle(150% at 0% 0%);
  }
}

@keyframes toastCloseAnimation {
  from {
    clip-path: circle(150% at 0% 0%);
  }

  to {
    clip-path: circle(0% at 0% 0%);
  }
}
