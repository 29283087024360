@import "../variables/colors.scss";
@import "../variables/shadwos.scss";

.dropzone {
  border: 0.2rem #dedede;
  border-radius: 0.5rem;
  background: $light-gray;
  height: 25rem;
  overflow-y: auto;
  padding: 1rem;
  border-style: dashed;
  border-color: $primary;
  border-width: 1.5px;
}

.dropzone i {
  font-size: 5rem;
}

.dz-message {
  color: $primary;
  font-weight: 500;
  font-size: initial;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-upload.ant-upload-select-picture-card,
.ant-upload-list-item {
  padding: 1.2rem !important;
  border-radius: $form-element-border-radius !important;
  box-shadow: $defult-shadow;
  border: none !important;
}

.upload-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.upload-button {
  height: 100%;
  width: 100%;
  border-radius: $upload-element-border-radius;
  display: grid;
  place-items: center center;
  background: $primary;
  color: $white;
}

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  // padding: top right bottom left
  padding: 1.2rem 1.5rem 1.2rem 1.5rem;
}

.container {
  position: absolute;
  left: 0;
  height: 70%;
  width: 75%;
  border-radius: $form-element-border-radius;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  padding: 2rem;
  overflow: hidden;
}

.upload {
  color: $white;
  z-index: 1;
  top: 0;
}

.ant-modal-content {
  border-radius: $form-element-border-radius !important;
}

.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  border-radius: $form-element-border-radius !important;
}
