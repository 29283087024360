.ant-tooltip .ant-tooltip-inner {
  background-color: $hover;
}

.ant-tooltip-arrow:before {
  background-color: $hover !important;
}

.danger-tooltip .ant-tooltip-inner {
  background-color: $danger !important;
  color: $black;
}

.danger-tooltip .ant-tooltip-arrow:before {
  background-color: $danger !important;
}
