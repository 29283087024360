@import "../variables/colors.scss";

* {
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 10px;
    background: $primary;
    background-clip: content-box;
    -webkit-background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, $primary, $hover, $primary);
    background-clip: border-box;
    -webkit-background-clip: border-box;
  }

  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    background-color: transparent;
  }

  /* Buttons */
  &::-webkit-scrollbar-button:single-button {
    background-color: transparent;

    display: block;
    background-size: 10px;
    background-repeat: no-repeat;
  }

  /* Up */
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 12px;
    width: 16px;
    background-position: center 4px;
    background-image: url("/assets/svgs/arrow-up.svg");
  }

  /* Down */
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 12px;
    width: 16px;
    background-position: center 2px;
    background-image: url("/assets/svgs/arrow-down.svg");
  }

  /* Left */
  &::-webkit-scrollbar-button:single-button:horizontal:decrement {
    height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='120' height='120' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  /* Right */
  &::-webkit-scrollbar-button:single-button:horizontal:increment {
    height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='120' height='120' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
  }
}
