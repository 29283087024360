@import "../variables/colors.scss";
@import "../variables/shadwos.scss";

.ant-table.ant-table-middle.ant-table-bordered.ant-table-rtl {
  background: rgba(0, 0, 0, 0);
}

.ant-table-thead th {
  background: opacity($color: $black) $primary !important;
  padding: 1px !important;
  padding-left: 10px !important;
}

.ant-table-wrapper {
  overflow: hidden;
}

table:last-child {
  border-radius: $form-element-border-radius !important;
  overflow: hidden;
}

.ant-tag {
  border-radius: $form-element-border-radius !important;
}

.ant-table-pagination.ant-pagination {
  justify-content: center !important;
}

.ant-pagination-item {
  border-radius: $form-element-border-radius !important;
  border-color: $semiDark !important;
  margin-top: 0.4rem;
}

.ant-pagination-item :hover {
  background-color: $form-element-background !important;
  border-radius: $form-element-border-radius !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}

.ant-dropdown {
  border-radius: $form-element-border-radius !important;
  overflow: hidden;
  box-shadow: $defult-shadow;
}

.ant-table-thead > tr > th {
  color: $grey !important;
}

[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > th,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > td,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > th,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td,
[dir="ltr"]
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > td {
  border-right: none;
}

.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
  border-inline-start: none !important;
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}

[dir="ltr"] .ant-table.ant-table-bordered > .ant-table-container {
  border: none !important;
}

.icon-button-table {
  border-radius: 0.5rem;
  display: inline-block;
  color: $primary;
  margin: 0 0 5px 0;
  cursor: pointer;
}

.icon-button-reject-table {
  border-radius: 0.5rem;
  display: inline;
  color: $danger;
  margin: 0 0 5px 0;
  cursor: pointer;
}

.button-table {
  margin-right: 2rem;
  border: none !important;
}

.button-table-en {
  margin-left: 2rem;
  border: none !important;
}

.button-table-action {
  margin-right: 1.5rem;
  border: none !important;
  background-color: transparent !important;
}

.edit-icon {
  color: $primary;
}

.ant-table-cell {
  border: none !important;
  width: 0.2%;
  padding: 10px !important;
}

// .ant-table-cell {
//     border: none !important;
//     width: 10;
// }

.ant-table-row.ant-table-row-level-0 {
  height: 5rem;
  background-color: $light-gray;
  cursor: pointer;
  margin-bottom: 1rem;
}

.ant-table table {
  border-spacing: 0 1rem;
}

.ant-table table td:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.ant-table table td:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.ant-table-content table {
  border: none !important;
}

.ant-spin-dot {
  top: 56% !important;
  inset-inline-start: 45% !important;
}

.ant-table-column-sorters {
  flex-direction: row-reverse;
}

.ant-table-column-sorter {
  margin-right: 0 !important;
  margin-left: 4px !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th::before {
  background-color: $black !important;
}

.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-inline: 1rem !important;
}

.ant-table-filter-dropdown-btns .ant-btn-link {
  color: $primary;
}

.input-search-t {
  border-radius: 34px;
  border: 3px solid $primary !important;
  padding-bottom: 8px;
}

.card-table .ant-card-body {
  padding: 12px 24px !important;
}
