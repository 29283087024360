:root {
  --primary-color: #179c8b;
  --secondry-color: #3b87fb;
  --hover-color: #4ed6c9;
}

$primary: var(--primary-color);
$secondry: var(--secondry-color);
$hover: var(--hover-color);
$primary-shadow: $hover;
$gray: #acacac;
$white: #ffffff;
$primary-extra-light: #c1ecea;
$backgroundPrim: var(--Grey-Lite, #fafafa);
$form-element-background: #f2f3f7;
$submenuebackground: #cbe4e6;
$soft-form-color: #e7f7f6;
$selectmenu: #d1edefed;
$black: #232528;
$grey: #3f3f3f;
$light: #f6f6f6;
$light-gray: #f2f3f7;
$light-semiGray: #e5e5e5;
$semiDark: #aaaaaa;
$blue-gray: #969fb7;
$danger: #ea5455;
$danger-extra-light: lighten(
  $color: $danger,
  $amount: 30,
);
$semiGreen: #28c76f;
$orange: #f79501;
$semiOrange: #f8af4133;
$form-element-border-radius: 1.3rem;
$upload-element-border-radius: 2rem;
$checkbox-border-radius: 0.5rem;
$navbar-border-radius: 0.1rem;
$navbar-border-radius: 0.1rem;
$button-radius: 0.5rem;
