@import "../variables/fonts.scss";

.st0 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.animated-logo {
  width: 135px;
  height: 45px;
}

.logo-text {
  color: white;
  position: absolute;
  top: 8%;
  margin-right: 0.5rem;
  font-family: $main-arabic-font;
  font-weight: 700;
  font-size: 1.4rem;
}

.loader-container {
  width: 15rem;
  text-align: center;
  justify-content: center;
}
