@import "../variables/colors.scss";
.form-element-container {
  .ant-input,
  .ant-select,
  .ant-picker,
  .ant-input-number {
    width: 100%;
    border-color: $primary;
    &::placeholder,
    &::-webkit-input-placeholder {
      transition: color 0.2s ease-in-out;
      color: $gray;
    }
    &:focus {
      background-color: $form-element-background;
      &::placeholder,
      &::-webkit-input-placeholder {
        color: $primary;
        border-radius: 0.5rem;
      }
    }
    &:hover {
      background-color: $form-element-background !important;
    }
    background-color: $form-element-background !important;
    color: $black;
  }
  .ant-custom-input {
    &:focus {
      background-color: $form-element-background;
      &::placeholder,
      &::-webkit-input-placeholder {
        color: $primary;
      }
    }
  }
  .ant-custom-input-error {
    &:focus {
      background-color: $danger-extra-light;
      &::placeholder,
      &::-webkit-input-placeholder {
        color: $danger;
      }
    }
  }
  .ant-input-disabled {
    background-color: $white !important;
  }
}
