@import "../variables/colors.scss";
.form-element-container {
  padding: 0.2rem;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 4px 4px rgba($color: $primary, $alpha: 0.2);
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.form-element-container:focus {
  padding: 0.3rem;
}
.form-element-focus-background {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background: $primary;
  width: 1rem;
  height: 1rem;
  z-index: 0;
}
.form-element-error-background {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background: $danger;
  width: 1rem;
  height: 1rem;
  z-index: 0;
}
.form-element-label {
  color: $black;
  margin: 0;
  margin-left: 1.2rem;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}
.form-element-error {
  @apply z-10 font-normal text-center p-1;
  color: $white;
  background: $danger;
  border-radius: 0.2rem;
}
.form-element-required-star {
  color: $danger;
}
