.squaer {
  width: 15px;
  height: 15px;
  border-radius: 10px;
  margin-right: 3px;
}

.squaer-text {
  font-size: 11px;
  margin-right: 8px;
}
